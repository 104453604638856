import React from "react";
import { Icons } from "../global/icon";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function MainSidebar({ onClick }) {
  const navContent = [
    {
      title: "Dashboard",
      icon: <Icons.DashoardIcon className="w-4 h-4 self-center" />,
      to: "/dashboard",
    },
    {
      title: "Settings",
      icon: <Icons.Settings className="w-4 h-4 self-center" />,
      to: "/dashboard/settings",
    },
    {
      title: "Invite User",
      icon: <Icons.UserPlus className="w-4 h-4 self-center" />,
      to: "/dashboard/invite-user",
    },
  ];
  return (
    <div className="w-full h-full pt-20 flex flex-col gap-10 items-center px-3">
      <div className="">
        <p className="text-lg font-semibold">Admin Dashboard</p>
      </div>
      <div className="flex flex-col gap-4">
        {navContent.map((nav, navIndex) => {
          return (
            <Link
              key={navIndex}
              to={nav.to}
              className="flex gap-2"
              onClick={onClick}
            >
              {nav.icon}
              <span>{nav.title}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

MainSidebar.prototype = {
  onClick: PropTypes.func,
};

export default MainSidebar;

import React from "react";
import { Icons } from "../global/icon";
import PropTypes from "prop-types";

function SearchBar({ className, placeholder }) {
  return (
    <div className={`w-full md:w-64 max-w-md relative rounded-md ${className} border border-slate-400 py-1`}>
      <input
        type="text"
        name="searchItem"
        className={`w-full px-2.5 pl-12 py-1  focus:outline-none border-0 focus:border-green-300`}
        placeholder={`${placeholder}`}
      />
      <span className="absolute left-0 top-0 bottom-0 border-r border-slate-400 bg-slate-200 rounded-tl-md rounded-bl-md flex items-center px-1.5">
        <Icons.Search
          className="w-6 h-6"
          strokeWidth={1.3}
          placeholder={placeholder}
        />
      </span>
    </div>
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SearchBar;

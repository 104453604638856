import React, { useEffect, useRef, useState } from "react";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import InputComponent from "../../../../components/dashboard/inputComponent";
import Label from "../../../../components/dashboard/label";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import InputField from "../../../../components/dashboard/inputField";
import {
  aboutSection,
  heroSection,
  ourServices,
  ourServicesTemplates,
  heroTemplates,
  aboutTemplates,
  ourServicesFieldArr,
  talkToUs,
  talkToUsTemplates,
} from "../../../../utils/dashboardFormData/otherPages/homePageData";
import InputFile from "../../../../components/dashboard/inputFile";
import AddButton from "../../../../components/dashboard/addButton";
import RemoveButton from "../../../../components/dashboard/removeButton";
import TextArea from "../../../../components/dashboard/textArea";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { homeSlice } from "../../../../redux/slice/home-slice";
import { debounce, isEqual } from "lodash";
import DashLoader from "../../../../components/global/dash-loader";
import { handleResponse } from "../../../../utils/handleResponse";
import {
  reverseTransformApiData,
  transformApiData,
} from "../../../../utils/transformData";
import { homeSectionMap } from "../../../../utils/sectionMapData";

function HeroSection() {
  const { register } = useFormContext();

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {heroSection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
              {field.type === "textArea" && (
                <TextArea
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
              {field.type === "file" && (
                <InputFile
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function AboutUsSection() {
  const { register } = useFormContext();
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="About Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {aboutSection.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
              {field.type === "textArea" && (
                <TextArea
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

function OurServices() {
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "listOfServices",
  });
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Our Services" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 ">
        {ourServices.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
              {field.type === "textArea" && (
                <TextArea
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}

        <div className="md:col-span-2">
          <AddButton
            onClick={() => append({ name: "", desc: "", id: "" })}
            content="Add Service"
            className={"gap-2"}
          />
        </div>

        {/* Field Array */}
        {fields.map((field, index) => {
          return (
            <div
              key={field.id}
              className="flex flex-col gap-4 md:cols-span-2 w-full self-center md:self-start"
            >
              <SectionHeading
                content={`Service ${index + 1}`}
                className="bg-slate-700 text-white"
              />
              <div className="flex gap-3">
                {ourServicesFieldArr.map((entity, entityIdx) => {
                  return (
                    <InputComponent key={entityIdx} className="">
                      <Label htmlFor={entity.id} label={entity.label} />
                      {entity.type === "text" && (
                        <InputField
                          type={entity.type}
                          name={`listOfServices[${index}].${entity.name}`}
                          id={entity.id}
                          placeholder={entity.placeholder}
                          register={register(
                            `listOfServices[${index}].${entity.name}`
                          )}
                        />
                      )}
                      {entity.type === "textArea" && (
                        <TextArea
                          name={`listOfServices[${index}].${entity.name}`}
                          id={entity.id}
                          placeholder={entity.placeholder}
                          register={register(
                            `listOfServices[${index}].${entity.name}`
                          )}
                        />
                      )}
                    </InputComponent>
                  );
                })}

                {index > 0 && (
                  <RemoveButton
                    className={"self-center mb-1"}
                    onClick={() => remove(index)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

function TalkToUsSection() {
  const { register } = useFormContext();
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Talk To Us Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {talkToUs.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}
      </div>
    </Section>
  );
}

// Main component
function HomePage() {
  const [isNew, setIsNew] = useState(true);
  const [response, setResponse] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const methods = useForm({
    defaultValues: {
      ...heroTemplates,
      ...aboutTemplates,
      ...ourServicesTemplates,
      ...talkToUsTemplates,
    },
  });
  const loading = useSelector((state) => state.homePage.loading);
  const error = useSelector((state) => state.homePage.error);
  const homeData = useSelector((state) => state.homePage.data);

  const isInitialized = useRef(false);

  // Watch form inputs for changes
  const watchedValues = methods.watch();
  const prevWatchedValues = useRef({}); // Track previous watched values

  const { makeRequest: getHomeData } = useRequest(
    "/home",
    "GET",
    {},
    homeSlice
  );
  const { makeRequest: createHomeData } = useRequest(
    "/home",
    "POST",
    {},
    homeSlice
  );
  const { makeRequest: updateHomeData } = useRequest(
    "/home",
    "PUT",
    {},
    homeSlice
  );

  // GET FORM DATA
  useEffect(() => {
    const getData = async () => {
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getHomeData();
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
      handleResponse(
        response,
        statusCode,
        error,
        homeData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!homeData) {
      const transformedData = transformApiData(homeData, homeSectionMap);
      if (transformedData && Object.keys(transformedData).length > 0) {
        methods.reset(transformedData);
        setIsNew(false);
        isInitialized.current = true;
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeData]);

  // CREATE AND UPDATE FUNCTION
  useEffect(() => {
    const handleCreateAndUpdate = debounce(async (data) => {
      setLoadingMessage(isNew ? "Creating data..." : "Updating data...");
      if (isNew) {
        const [, initialStatusCode, initialResponse] = await createHomeData(
          data
        );
        setResponse(initialResponse);
        setStatusCode(initialStatusCode);
        setIsNew(false); // Switch to update mode after creation
      } else {
        const [, initialStatusCode, initialResponse] = await updateHomeData(
          data
        );
        setResponse(initialResponse);
        setStatusCode(initialStatusCode);
      }
      const toastMessage = isNew
        ? "Successfully created!"
        : "Successfully updated";

      const toastResponse = handleResponse(
        response,
        statusCode,
        error,
        homeData,
        toastMessage
      );
      if (toastResponse === "successful") return;
    }, 3000);

    // Only run if the form has been initialized
    if (
      isInitialized.current &&
      !isEqual(prevWatchedValues.current, watchedValues)
    ) {
      prevWatchedValues.current = watchedValues;
      const reversedTransformData = reverseTransformApiData(
        watchedValues,
        homeSectionMap
      );
      handleCreateAndUpdate(reversedTransformData);
    }

    // Cleanup the debounce when component unmounts
    return () => {
      handleCreateAndUpdate.cancel && handleCreateAndUpdate.cancel(); // Cancel debounce if possible
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues, isNew]);

  // statusCode, response, error, data

  if (loading) {
    return <DashLoader message={loadingMessage} />;
  }

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col h-screen gap-8 pt-6 pb-10">
        <h1 className="text-xl self-center md:text-xl font-semibold">
          Home Page
        </h1>
        <div className="h-full overflow-y-auto pb-7">
          <HeroSection />
          <AboutUsSection />
          <OurServices />
          <TalkToUsSection />
        </div>
      </form>
    </FormProvider>
  );
}

export default HomePage;

// Use this function only if the data is a key

export const replaceObjectKeys = (obj, newKeys) => {
  // Get the object's entries
  const entries = Object.entries(obj);

  // Ensure the newKeys array has the same length as the entries array
  if (entries.length !== newKeys.length) {
    throw new Error(
      "New keys array length does not match the number of keys in the object."
    );
  }

  // Map through entries and replace keys
  const newObj = entries.reduce((acc, [_, value], index) => {
    const newKey = newKeys[index];
    acc[newKey] = value;
    return acc;
  }, {});

  return newObj;
};

export const heroSection = [
  {
    type: "text",
    name: "heroTitle",
    id: "heroTitle",
    label: "Title",
    placeholder: "We are a creative ...",
  },
  {
    type: "text",
    name: "heroBody",
    id: "heroBody",
    label: "Body",
    placeholder: "We design & create ...",
  },
  {
    type: "textArea",
    name: "heroPara",
    id: "heroPara",
    label: "Description",
    placeholder: "Elevate your business...",
  },
  {
    type: "text",
    name: "heroBtn",
    id: "heroBtn",
    label: "Button",
    placeholder: "Contact Us",
  },
  // {
  //   type: "text",
  //   name: "heroRecommendation",
  //   id: "heroRecommendation",
  //   label: "Hero Recommendation",
  //   placeholder: "Trusted by 500, 000 ...",
  // },

  // {
  //   type: "file",
  //   name: "heroImage",
  //   id: "heroImage",
  //   label: "Hero Image",
  //   placeholder: "Choose Image",
  // },
];

export const heroTemplates = {
  heroTitle: "",
  heroBody: "",
  heroPara: "",
  heroBtn: "",
  // heroRecommendation: "",
  // heroImage: null,
  // brandCompanies: [{ brandCompany: null }],
};

export const aboutSection = [
  {
    type: "text",
    name: "aboutBody",
    id: "aboutBody",
    label: "Body",
    placeholder: "...",
  },
  {
    type: "text",
    name: "aboutSubBody",
    id: "aboutSubBody",
    label: "Sub-Body",
    placeholder: "Creative solutions",
  },
  {
    type: "textArea",
    name: "aboutPara",
    id: "aboutPara",
    label: "Paragraph",
    placeholder: "We ...",
  },
  {
    type: "text",
    name: "aboutButton",
    id: "aboutButton",
    label: "Button",
    placeholder: "Learn button",
  },
];

export const aboutTemplates = {
  aboutBody: "",
  aboutSubBody: "",
  aboutPara: "",
  aboutButton: "",
};

export const ourServices = [
  {
    type: "text",
    name: "servicesMainTitle",
    id: "servicesMainTitle",
    label: "Body",
    placeholder: "Experience excellence",
  },
  {
    type: "textArea",
    name: "servicesPara",
    id: "servicesPara",
    label: "Paragraph",
    placeholder: "",
  },
  // {
  //   type: "text",
  //   name: "servicesButton",
  //   id: "servicesButton",
  //   label: "Button",
  //   placeholder: "...",
  // },
];

export const ourServicesFieldArr = [
  {
    type: "text",
    name: "name",
    id: "name",
    label: "Service Title",
    placeholder: "...",
  },
  {
    type: "textArea",
    name: "desc",
    id: "desc",
    label: "Service Description",
    placeholder: "Enim odio ut nulla malesuada...",
  },
];

export const ourServicesTemplates = {
  servicesLabel: "",
  servicesMainTitle: "",
  servicesPara: "",
  servicesButton: "",
  listOfServices: [
    {
      name: "",
      desc: "",
    },
  ],
};

export const talkToUs = [
  {
    type: "text",
    name: "talkToUsTitle",
    id: "talkToUsTitle",
    label: "Title",
    placeholder: "..",
  },
  {
    type: "text",
    name: "talkToUsButton",
    id: "talkToUsButton",
    label: "Contact Button",
    placeholder: "...",
  },
];

export const talkToUsTemplates = {
  talkToUsTitle: "",
  talkToUsButton: "",
};

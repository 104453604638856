import React from "react";

function InviteUser() {
  return (
    <div className="w-full">
      <p>This is invite user page</p>
    </div>
  );
}

export default InviteUser;

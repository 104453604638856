import React from 'react'
import PropTypes from 'prop-types';


function InputComponent({children, className}) {
  return (
    <div className={`flex flex-col gap-2 ${className}`}>
        {children}
    </div>
  )
}

InputComponent.prototype ={
    children: PropTypes.elementType.isRequired,
    className: PropTypes.string.isRequired,
}

export default InputComponent
import React from "react";
import { Icons } from "../global/icon";

function Header({ onClick }) {
  return (
    <header className="w-full px-2 md:px-8 py-4 flex justify-between border-b border-gray-300 bg-slate-50 fixed left-0 top-0 z-20">
      <div className="flex gap-3">
        {/* Hamburger */}
        <button className="border border-black rounded-md md:hidden" onClick={onClick}>
          <Icons.Menu className="cursor-pointer" />
        </button>
        {/* Logo */}
        <div className="flex gap-3">
          <img
            src="/images/padding_logo.jpg"
            alt="Padding Logo"
            className="w-5 h-4 md:w-7 md:h-6 self-center"
          />
          <h1 className="4xl font-semibold hidden md:block">Padding</h1>
        </div>
      </div>

      {/* Other icon */}
      <div className="flex gap-3 md:gap-6">
        <Icons.Moon className="self-center w-4 h-4 md:w-5 md:h-5" />
        <Icons.Bell className="self-center w-4 h-4 md:w-5 md:h-5" />
        <div className="rounded-full w-5 h-5 md:w-7 md:h-7 p-0.5 md:p-1 flex justify-center items-center border border-black cursor-pointer">
          <p className="w-full h-full font-semibold text-[0.6rem] md:text-sm">
            N0
          </p>
          {/* <img src="" alt="" /> */}
        </div>
      </div>
    </header>
  );
}

export default Header;

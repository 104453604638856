import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AuthButton from "../../components/global/auth-button";
import EyeToggle from "../../components/global/eye-toggle";
import { registerFormFields } from "../../utils/registerFormFields";
import { Icons } from "../../components/global/icon";

function NewPassword() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cPasswordVisible, setCPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState, watch } = useForm();
  const { errors } = formState;
  const password = watch("password");


  const setNewPassword = async (data) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    console.log("Form submitted!", data);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleCPasswordVisibility = () => {
    setCPasswordVisible(!cPasswordVisible);
  };

  return (
    <div className="flex overflow-hidden h-screen">
      {/* Image section */}
      <div className="hidden md:block">
        <img
          src="/images/cms_bg.jpg"
          alt="Background"
          className="lg:w-[50vw] object-cover md:h-screen"
        />
      </div>

      <main className="flex justify-center items-center px-10 lg:px-0 w-full lg:w-[50vw] h-full">
        <div className="flex flex-col gap-9 w-full lg:w-[25rem] max-w-[20rem] lg:max-w-[25rem]">
          {/* Header */}
          <img
            src="/images/padding_logo.jpg"
            alt="Padding Logo"
            className="w-10 h-10 self-center"
          />
          <div className="flex flex-col gap-1 items-center">
            <h1 className="font-semibold text-xl lg:text-2xl">New Password</h1>
            <p className="text-xs">Please enter your new password below</p>
          </div>

          {/* Form section */}
          <form
            onSubmit={handleSubmit(setNewPassword)}
            className="flex flex-col gap-7"
          >
            {/* Password and confirm password fields */}
            {registerFormFields.map((field, fieldIndex) => {
              const isPasswordVisible =
                field.name === "password" ? passwordVisible : cPasswordVisible;

              const toggleVisibility =
                field.name === "password"
                  ? togglePasswordVisibility
                  : toggleCPasswordVisibility;

              return (
                <div key={fieldIndex} className="flex flex-col gap-2 w-full">
                  <label htmlFor={field.id} className="text-sm font-medium">
                    {field.label}
                  </label>
                  {/* Input */}
                  <div className="relative">
                    <input
                      type={`${isPasswordVisible ? "text" : field.type}`}
                      {...register(field.name, {
                        required: {
                          value: true,
                          message: `${field.placeholder} is required`,
                        },
                        pattern:
                          field.name === "password"
                            ? {
                                value:
                                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                                message:
                                  "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character",
                              }
                            : undefined,
                        validate:
                          field.name === "confirm_password"
                            ? (value) =>
                                value === password || "Passwords do not match"
                            : undefined,
                      })}
                      placeholder={field.placeholder}
                      id={field.id}
                      className="border border-slate-400 pl-4 pr-12 py-2 focus:outline-none w-full focus:border-green-300 rounded-md"
                    />

                    {/* Eye toggle */}
                    {!isPasswordVisible && (
                      <EyeToggle
                        content={
                          <Icons.Eye strokeWidth={1.4} className="text-slate-500" />
                        }
                        className="absolute top-2 right-3"
                        onClick={toggleVisibility}
                      />
                    )}
                    {isPasswordVisible && (
                      <EyeToggle
                        content={
                          <Icons.EyeOff
                            strokeWidth={1.3}
                            className="text-slate-500"
                          />
                        }
                        className="absolute top-2 right-3"
                        onClick={toggleVisibility}
                      />
                    )}
                  </div>
                  {errors[field.name] && (
                    <p className="text-red-700 text-xs">
                      {errors[field.name]?.message}
                    </p>
                  )}
                </div>
              );
            })}

            <AuthButton
              content="Reset Password"
              className="w-full"
              isLoading={isLoading}
            />
          </form>
          <Link className="flex gap-2 self-center text-sm" to={"/"}>
            <Icons.ArrowLeft width={18} height={18} className="self-center" />
            <span>Back home</span>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default NewPassword;

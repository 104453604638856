import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function ProtectedRoute({ children }) {
  const token =
    useSelector((state) => state.user.token) || localStorage.getItem("token");

  const logout = useSelector((state) => state.user.logout);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      if (logout) {
        toast.success("Successfully logged out!");
        setTimeout(() => {
          navigate("/auth/login");
        }, 3000);
      } else {
        toast.error("Authentication failed...please log in");
        setTimeout(() => {
          navigate("/auth/login");
        }, 3000);
      }
    }
  }, [token, logout, navigate]);

  return token ? children : null; // Render children if token exists, otherwise nothing
}

export default ProtectedRoute;

import React from "react";
import PropTypes from "prop-types";

function InputField({ type, name, id, placeholder, register, className }) {
  return (
    <input
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      {...register}
      className={`px-2 py-2 border border-slate-300 rounded-md focus:outline-none focus:border-green-400 ${className}`}
    />
  );
}

InputField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  register: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default InputField;

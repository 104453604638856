import React from "react";
import PropTypes from 'prop-types';
import { Icons } from "./icon";


function EyeToggle({className="", onClick=() => null, content=<Icons.Eye />}) {
  return (
    <>
      <button className={`w-fit h-fit p-0 cursor-pointer ${className}`} onClick={onClick} type="button">
        {/* <EyeOff width={20} height={20} /> */}
        {content}
      </button>
    </>
  );
}

EyeToggle.propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};



export default EyeToggle;

export const registerFormFields = [
    {
        name: "password",
        id: "password",
        type: "password",
        label: "Password",
        placeholder: "password",
    },
    {
        name: "confirm_password",
        id: "confirm_password",
        type: "password",
        label: "Confirm Password",
        placeholder: "confirm password",
    },
]
import React, { useEffect, useRef, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import {
  blogFieldArr,
  blogSectionData,
  blogTemplates,
} from "../../../../utils/dashboardFormData/blog/blogPageData";
import InputComponent from "../../../../components/dashboard/inputComponent";
import Label from "../../../../components/dashboard/label";
import InputField from "../../../../components/dashboard/inputField";
import TextArea from "../../../../components/dashboard/textArea";
import AddButton from "../../../../components/dashboard/addButton";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { blogSlice } from "../../../../redux/slice/blog-slice";
import {
  reverseTransformApiData,
  transformApiData,
} from "../../../../utils/transformData";
import { handleResponse } from "../../../../utils/handleResponse";
import { debounce, isEqual } from "lodash";
import DashLoader from "../../../../components/global/dash-loader";
import { blogSectionMap } from "../../../../utils/sectionMapData";
import RemoveButton from "../../../../components/dashboard/removeButton";

function BlogSection() {
  const { register, control } = useFormContext();
  const {
    fields: blogField,
    append: appendBlog,
    remove: removeBlog,
  } = useFieldArray({
    control,
    name: "blogs",
  });
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Projects" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        {blogSectionData.map((field, index) => {
          return (
            <InputComponent key={index} className="">
              <Label htmlFor={field.id} label={field.label} />
              {field.type === "text" && (
                <InputField
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
              {field.type === "textArea" && (
                <TextArea
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  register={register(field.name)}
                />
              )}
            </InputComponent>
          );
        })}

        {/* Field array section */}
        <div className="md:col-span-2">
          <AddButton
            onClick={() =>
              appendBlog({
                title: "",
                url: "",
                author: "",
                readTime: "",
                image: "",
              })
            }
            content="Add Blog"
            className={"gap-2"}
          />
        </div>

        {/* Field Array */}
        <div className="w-full col-span-1 md:col-span-2 flex flex-col gap-2">
          {blogField.map((blog, blogIdx) => {
            return (
              <div
                key={blog.id}
                className="flex flex-col gap-4 w-full self-center md:self-start"
              >
                <SectionHeading
                  content={`blog ${blogIdx + 1}`}
                  className="bg-slate-700 text-white"
                />
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 ">
                  {blogFieldArr.map((entity, entityIdx) => {
                    return (
                      <InputComponent key={entityIdx} className="">
                        <Label htmlFor={entity.id} label={entity.label} />
                        {entity.type === "text" && (
                          <InputField
                            type={entity.type}
                            name={`blogs[${blogIdx}].${entity.name}`}
                            id={entity.id}
                            placeholder={entity.placeholder}
                            register={register(
                              `blogs[${blogIdx}].${entity.name}`
                            )}
                          />
                        )}
                      </InputComponent>
                    );
                  })}

                  {blogIdx > 0 && (
                    <RemoveButton
                      className={"self-center mb-1"}
                      onClick={() => removeBlog(blogIdx)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

function BlogPage() {
  const [isNew, setIsNew] = useState(true);
  const [response, setResponse] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const methods = useForm({
    defaultValues: {
      ...blogTemplates,
    },
  });

  const loading = useSelector((state) => state.blogPage.loading);
  const error = useSelector((state) => state.blogPage.error);
  const blogData = useSelector((state) => state.blogPage.data);

  const isInitialized = useRef(false);

  // Watch form inputs for changes
  const watchedValues = methods.watch();
  const prevWatchedValues = useRef({}); // Track previous watched values

  const { makeRequest: getBlogData } = useRequest(
    "/blog",
    "GET",
    {},
    blogSlice
  );
  const { makeRequest: createBlogData } = useRequest(
    "/blog",
    "POST",
    {},
    blogSlice
  );
  const { makeRequest: updateBlogData } = useRequest(
    "/blog",
    "PUT",
    {},
    blogSlice
  );

  // GET FORM DATA
  useEffect(() => {
    const getData = async () => {
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getBlogData();
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
      handleResponse(
        response,
        statusCode,
        error,
        blogData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!blogData) {
      const transformedData = transformApiData(blogData, blogSectionMap);
      console.log(transformedData, "hhhhhh");

      if (transformedData && Object.keys(transformedData).length > 0) {
        // Populate the form with fetched data
        methods.reset(transformedData);
        setIsNew(false);
        isInitialized.current = true;
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogData]);

  // CREATE AND UPDATE FUNCTION
  useEffect(() => {
    const handleCreateAndUpdate = debounce(async (data) => {
      setLoadingMessage(isNew ? "Creating data..." : "Updating data...");
      if (isNew) {
        const [, initialStatusCode, initialResponse] = await createBlogData(
          data
        );
        setResponse(initialResponse);
        setStatusCode(initialStatusCode);
        setIsNew(false);
      } else {
        const [, initialStatusCode, initialResponse] = await updateBlogData(
          data
        );
        setResponse(initialResponse);
        setStatusCode(initialStatusCode);
      }
      const toastMessage = isNew
        ? "Successfully created!"
        : "Successfully updated";

      const toastResponse = handleResponse(
        response,
        statusCode,
        error,
        blogData,
        toastMessage
      );
      if (toastResponse === "successful") return;
    }, 3000);

    // Only run if the form has been initialized
    if (
      isInitialized.current &&
      !isEqual(prevWatchedValues.current, watchedValues)
    ) {
      prevWatchedValues.current = watchedValues;
      const reversedTransformData = reverseTransformApiData(
        watchedValues,
        blogSectionMap
      );
      handleCreateAndUpdate(reversedTransformData);
    }

    // Cleanup the debounce when component unmounts
    return () => {
      handleCreateAndUpdate.cancel && handleCreateAndUpdate.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues, isNew]);

  if (loading) {
    return <DashLoader message={loadingMessage} />;
  }

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-8 pt-6 pb-10">
        <AddButton content="Add blog" className={"gap-2"} />
        <h1 className="text-xl self-center md:text-xl font-semibold">
          Blogs Page
        </h1>
        <BlogSection />
      </form>
    </FormProvider>
  );
}

export default BlogPage;

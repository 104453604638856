// This maps the API data keys to form field labels.
// It is used to transform the raw API data into a structure
// that matches your form fields and labels. Nested arrays and
// objects are handled by specifying mappings for individual fields.

// Home Page
export const homeSectionMap = {
  heroTitle: "hero.title",
  heroBody: "hero.body",
  heroPara: "hero.paragraph",
  heroBtn: "hero.contactBtn",

  aboutBody: "about.body",
  aboutSubBody: "about.subBody",
  aboutPara: "about.paragraph",
  aboutButton: "about.learnBtn",

  servicesMainTitle: "services.body",
  servicesPara: "services.paragraph",
  listOfServices: {
    section: "services.services",
    arrayField: true,
    itemMap: {
      name: "title",
      desc: "body",
    },
  },

  talkToUsTitle: "section.title",
  talkToUsButton: "section.contactBtn",
};

// Project page
export const projectSectionMap = {
  projectBody: "body",
  projectPara: "paragraph",
  projects: {
    arrayField: true,
    section: "projects",
    itemMap: {
      title: "title",
      paragraph: "paragraph",
      image: "image",
      // Field Array of strings
      keywords: {
        arrayField: true,
        isPrimitive: true, // Indicates an array of strings
      },
      // Filed Array of objects
      reviews: {
        section: "projects.reviews",
        arrayField: true,
        itemMap: {
          name: "name",
          comment: "comment",
          image: "image",
        },
      },
    },
  },
};

// Blog Page
export const blogSectionMap = {
  body: "body",
  paragraph: "paragraph",
  blogs: {
    arrayField: true,
    section: "blogs",
    itemMap: {
      title: "title",
      url: "url",
      author: "author",
      readTime: "readTime",
      image: "image",
    },
  },
};

import React from "react";

function DashSettings() {
  return (
      <div className="w-full">
        <h1>Welcome John Doe</h1>
        <div>
          
        </div>
      </div>
  );
}

export default DashSettings;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  name: null,
  email: null,
  token: null,
  loading: false,
  error: null,
  logout: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestSuccess: (state, action) => {
      state.loading = false;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.userId = action.payload.userId;
      state.token = action.payload.token;
      state.error = null;
      state.logout = false;
    },
    requestFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.logout = true;
      localStorage.removeItem("token")
    },
  },
});

export const { startRequest, requestSuccess, requestFailure, logout } =
  userSlice.actions;
export default userSlice.reducer;

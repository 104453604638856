import React, { useState } from "react";
import Header from "./Header";
import MainSidebar from "./MainSidebar";
import ProjectSidebar from "./ProjectSidebar";
import { Outlet, useLocation } from "react-router-dom";
import { Icons } from "../global/icon";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slice/user-slice";

function Layout() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  // const token = useSelector((state) => state.user.token);
  // const loading = useSelector((state) => state.user.loading);
  // const errorMessage = useSelector((state) => state.user.error);
  // const userData = {
  //   name: useSelector((state) => state.user.name),
  //   email: useSelector((state) => state.user.email),
  //   userId: useSelector((state) => state.user.userId),
  // };

  // const { makeRequest: getUserData, response } = useRequest(
  //   "/user",
  //   "GET",
  //   userSlice
  // );

  // useEffect(() => {
  //   if (!token) {
  //     toast.error("Authentication failed...please log in");
  //     setTimeout(() => {
  //       navigate("/auth/login");
  //     }, 3000);
  //   }
  //   const [statusCode] = getUserData();
  //   if (errorMessage && statusCode !== 200) {
  //     toast.error(errorMessage.payload);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // if (loading)
  //   return (
  //     <div className="fixed inset-0 bg-white flex justify-end items-center">
  //       <span>Loading ...</span>
  //     </div>
  //   );

  const isProjectsRoute = location.pathname.startsWith("/dashboard/projects");
  const isStatic = location.pathname.split("/").includes("static");
  const isViews = location.pathname.split("/").includes("views");
  const isBlogs = location.pathname.split("/").includes("blogs");

  function openSidebar() {
    setIsOpen(true);
  }
  function closeSidebar() {
    setIsOpen(false);
  }
  function handleLogout() {
    console.log("Processing logout...");
    dispatch(logout());
    console.log("logout successfuly");
  }
  return (
    <div className="w-full h-screen overflow-hidden flex flex-col">
      <Header onClick={openSidebar} />

      {/* Mobile Menu */}
      {isOpen && (
        <aside className="bg-white/90 fixed inset-0 md:hidden w-full z-30 flex flex-col gap-5 pb-10 pt-10 overflow-y-auto">
          <button
            className="border border-black rounded-md self-center"
            onClick={closeSidebar}
          >
            <Icons.Cancel className="cursor-pointer md:hidden" />
          </button>
          {isProjectsRoute ? (
            <ProjectSidebar onClick={closeSidebar} />
          ) : (
            <MainSidebar onClick={closeSidebar} />
          )}
          <div className="flex flex-col gap-5 w-full items-center">
            <button
              className="flex gap-2 text-sm font-semibold"
              type="button"
              onClick={handleLogout}
            >
              <Icons.LogOut className="w-4 h-4 self-center" />
              <span>Logout</span>
            </button>
          </div>
        </aside>
      )}

      {/* Body */}
      <div className="w-full h-full md:grid md:grid-cols-[200px_1fr]">
        <aside className="border-r border-gray-300 hidden md:flex md:flex-col md:gap-5 pb-10 overflow-y-auto">
          {isProjectsRoute ? <ProjectSidebar /> : <MainSidebar />}
          <div className="flex flex-col gap-5 w-full items-center">
            <button
              className="flex gap-2 text-sm font-semibold"
              type="button"
              onClick={handleLogout}
            >
              <Icons.LogOut className="w-4 h-4 self-center" />
              <span>Logout</span>
            </button>
          </div>
        </aside>
        {/* Main */}
        <main className="flex flex-col gap-5 w-full h-full overflow-auto px-7 pt-5 pb-10 mt-[3.8rem]">
          {!isProjectsRoute && (
            <h1 className="text-xl md:text-2xl font-semibold">
              Welcome John Doe!
            </h1>
          )}
          {isProjectsRoute && (
            <h1 className="text-xl italic md:text-2xl font-semibold">
              <span>Pinnacle Padding</span>{" "}
              {isStatic
                ? "(Static Section)"
                : isBlogs
                ? "(Blogs Section)"
                : isViews
                ? "(Views Section)"
                : ""}
            </h1>
          )}

          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Layout;

import React from "react";
import PropTypes from "prop-types";
import Loader from "./loader-page"

function AuthButton({ content="Add Content", className="", isLoading=false }) {
  return (
    <button
      className={`bg-green-400 rounded-md px-4 py-2 border border-slate-400 text-sm font-medium ${className}`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <span className="">{content}</span>
      )}
    </button>
  );
}

AuthButton.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

export default AuthButton;

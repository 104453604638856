import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function ProjectCard({
  title = "New project",
  description = "This is a new project",
  to=""
}) {
  return (
    <Link
      to={to}
      className="text-[0.6rem] md:text-sm border border-slate-400 bg-gray-400 shadow-lg flex flex-col gap-2 px-2 py-2 pt-3 items-center rounded-md min-w-40"
    >
      {/* Project Logo */}
      <div className="rounded-full w-5 h-5 md:w-7 md:h-7 p-0.5 md:p-1 flex justify-center items-center border border-black cursor-pointer">
        <p className="w-full h-full font-semibold ">N0</p>
        {/* <img src="" alt="" /> */}
      </div>

      {/* Project title */}
      <div className="flex flex-col gap-0.5">
        <h1 className="text-lg font-semibold text-center">{title}</h1>
        <p className="text-center">{description}</p>
      </div>
    </Link>
  );
}

ProjectCard.prototypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  to: PropTypes.string,
};

export default ProjectCard;

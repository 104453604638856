import React from "react";
import { toast } from "react-toastify";

function Test() {
  const notify = () =>
    toast.error("🦄 Wow so easy!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    });
  return (
    <div>
      <button onClick={notify}>show toast</button>
    </div>
  );
}

export default Test;

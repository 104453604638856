import React from "react";
import PropTypes from "prop-types";

function DashLoader({ message = "" }) {
  return (
    <div className="w-full h-full relative inset-x-0 inset-y-0 flex justify-center items-center py-7">
      <div className="inset-x-0 inset-y-0"></div>
      <p className="">{message}</p>
    </div>
  );
}

DashLoader.propTypes = {
  message: PropTypes.string,
};

export default DashLoader;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icons } from "../global/icon";

function SingleViewsCard({ content = "Type in your content"}) {
  return (
    <div
      className={`px-3 py-2 `}
    >
      <span className="font-normal">{content}</span>
    </div>
  );
}

SingleViewsCard.prototype = {
  content: PropTypes.any.isRequired,
};


function ViewsCard({content, className, onClick}) {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      className={`relative max-w-60 rounded-md border border-slate-300 max-h-60 text-sm ${className}`}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <SingleViewsCard content={content} />

      {/* Hover section */}
      {hovered && (<div className="absolute inset-0 z-10 pr-3 flex justify-end items-center bg-black/20">
        <button className="w-fit h-fit bg-white rounded-md"onClick={onClick}>
            <Icons.Edit className="w-5 w- h-5 text-slate-700 stroke-green-600" strokeWidth={2} />
        </button>
      </div>)}
    </div>
  );
}

ViewsCard.prototype = {
    content: PropTypes.any.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

export default ViewsCard;

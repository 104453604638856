export function transformApiData(data, sectionMap) {
  const transformedData = {};

  for (const mapKey in sectionMap) {
    const mapping = sectionMap[mapKey];

    if (typeof mapping === "string") {
      // Handle simple fields
      const parts = mapping.split(".");
      if (parts.length === 1) {
        // No dot, meaning it's a top-level field
        transformedData[mapKey] = data[parts[0]] || "";
      } else if (parts.length === 2) {
        // There's a dot, meaning it's a nested field
        const [section, field] = parts;
        transformedData[mapKey] = data[section]?.[field] || "";
      }
    } else if (mapping.arrayField) {
      // Handle array fields
      const section = mapping.section;

      transformedData[mapKey] = (data[section] || []).map((item) => {
        if (mapping.isPrimitive) {
          return item; // Directly return primitive values (e.g., strings)
        } else {
          const transformedItem = {};
          for (const itemKey in mapping.itemMap) {
            const itemMapping = mapping.itemMap[itemKey];

            if (typeof itemMapping === "string") {
              // Direct mapping for string fields
              transformedItem[itemKey] = item[itemMapping] || "";
            } else if (itemMapping.arrayField) {
              if (itemMapping.isPrimitive) {
                // Handle array of strings
                transformedItem[itemKey] = item[itemKey] || [];
              } else {
                // Handle array of objects
                transformedItem[itemKey] = (item[itemKey] || []).map(
                  (subItem) => {
                    const subTransformedItem = {};
                    for (const subItemKey in itemMapping.itemMap) {
                      subTransformedItem[subItemKey] =
                        subItem[itemMapping.itemMap[subItemKey]] || "";
                    }
                    return subTransformedItem;
                  }
                );
              }
            }
          }
          return transformedItem;
        }
      });
    }
  }

  return transformedData;
}

// Function to reverse the action of the above
export function reverseTransformApiData(transformedData, sectionMap) {
  const data = {};

  for (const mapKey in sectionMap) {
    const mapping = sectionMap[mapKey];

    if (typeof mapping === "string") {
      // Handle simple fields
      const parts = mapping.split(".");
      if (parts.length === 1) {
        // No dot, meaning it's a top-level field
        data[parts[0]] = transformedData[mapKey] || "";
      } else if (parts.length === 2) {
        // There's a dot, meaning it's a nested field
        const [section, field] = parts;
        if (!data[section]) {
          data[section] = {};
        }
        data[section][field] = transformedData[mapKey] || "";
      }
    } else if (mapping.arrayField) {
      // Handle array fields
      const [section, arrayName] = mapping.section.split(".");
      if (!data[section]) {
        data[section] = {};
      }

      data[section][arrayName] = (transformedData[mapKey] || []).map((item) => {
        const originalItem = {};
        for (const itemKey in mapping.itemMap) {
          const itemMapping = mapping.itemMap[itemKey];

          if (typeof itemMapping === "string") {
            // Direct mapping for string fields
            originalItem[itemMapping] = item[itemKey] || "";
          } else if (itemMapping.arrayField) {
            if (itemMapping.isPrimitive) {
              // Handle array of strings
              originalItem[itemKey] = item[itemKey] || [];
            } else {
              // Handle array of objects
              originalItem[itemKey] = (item[itemKey] || []).map((subItem) => {
                const subOriginalItem = {};
                for (const subItemKey in itemMapping.itemMap) {
                  subOriginalItem[itemMapping.itemMap[subItemKey]] =
                    subItem[subItemKey] || "";
                }
                return subOriginalItem;
              });
            }
          }
        }
        return originalItem;
      });
    }
  }

  return data;
}

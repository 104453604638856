import React from "react";

function Loader() {
  return (
    <div className=" flex gap-3 justify-center">
      <svg
        className="animate-spin h-5 border-4 border-slate-500 border-t-transparent border-r-transparent w-5 self-center rounded-full ..."
        viewBox="0 0 24 24"
      >
        {/*  */}
      </svg>
      <span>Processing...</span>
    </div>
  );
}

export default Loader;

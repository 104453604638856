import React from "react";

function TextArea({ name, id, placeholder, register, className }) {
  return (
    <div className="">
      <textarea
        name={name}
        id={id}
        placeholder={placeholder}
        {...register}
        className={`px-2 py-2 border border-slate-300 rounded-md focus:outline-none focus:border-green-400 w-full ${className}`}
        rows={5}
      ></textarea>
    </div>
  );
}

export default TextArea;

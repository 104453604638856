import React from "react";
import { Link } from "react-router-dom";
import { Icons } from "../components/global/icon";

function NotFound() {
  return (
    <div className="w-full h-screen overflow-hidden flex flex-col justify-center items-center bg-[#121212] text-white">
      <h2 className="text-2xl font-bold mb-4">404 - Page Not Found</h2>
      <p className="mb-4">
        Sorry, the page you are looking for does not exist.
      </p>
      <Link to="/" className="flex gap-3">
        <Icons.ArrowLeft className="w-5 h-5" />
        <span className="text-blue-500 hover:underline">
          Go back to the homepage
        </span>
      </Link>
    </div>
  );
}

export default NotFound;

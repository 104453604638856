import React from 'react'
import PropTypes from "prop-types";


function Label({htmlFor="", label="Add Label", className}) {
  return (
    <label htmlFor={htmlFor} className={`text-sm font-medium ${className}`} >
        {label}
    </label>
  )
}

Label.prototype = {
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Label
import routes from "./routes/routes";
import store from "./redux/store";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <div className="w-full h-full">{routes}</div>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;

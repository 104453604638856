import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/user-slice";
import homePageReducer from "./slice/home-slice";
import projectReducer from "./slice/project-slice";
import blogReducer from "./slice/blog-slice";
import serviceReducer from "./slice/service-slice";

const store = configureStore({
  reducer: {
    user: userReducer,
    homePage: homePageReducer,
    projectPage: projectReducer,
    blogPage: blogReducer,
    servicePage: serviceReducer,
  },
});

export default store;

import React from "react";
import { Icons } from "../global/icon";
import PropTypes from "prop-types";

function RemoveButton({ onClick, content = "", className }) {
  return (
    <button
      className={`w-fit h-fit px-2 py-2 bg-red-400 flex justify-center rounded-md ${className}`}
      onClick={onClick}
      type="button"
    >
      <Icons.TrashTwo className="w-5 h-5 self-center" strokeWidth={2} />
      <span>{content}</span>
    </button>
  );
}

RemoveButton.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func,
};

export default RemoveButton;

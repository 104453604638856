import React, { useState } from "react";
import PropTypes from "prop-types";

function InputFile({
  name,
  id,
  placeholder = "Choose an Image",
  register,
  className,
}) {
  const [selected, setSelected] = useState({ file: null, selected: false });
  return (
    <label
      htmlFor={id}
      className={`px-2 py-2 border border-slate-300 rounded-md ${className}`}
    >
      <input
        type="file"
        name={name}
        id={id}
        {...register}
        className="hidden"
        onChange={(e) =>
          setSelected({ file: e.target.files[0], present: true })
        }
      />
      {selected.present ? (
        <span>{selected.file?.name}</span>
      ) : (
        <span>{placeholder}</span>
      )}
    </label>
  );
}

InputFile.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default InputFile;

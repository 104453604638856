import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';


function LinkButton({content="Add Content", className="", route="."}) {
  return (
    <>
      <Link className={`bg-green-400 rounded-md px-4 py-2 border border-slate-400 ${className}`} to={route}>
        <span className="text-sm font-medium">{content}</span>
      </Link>
    </>
  );
}

LinkButton.prototype = {
  content: PropTypes.string,
  className: PropTypes.string,
  route: PropTypes.string,
}

export default LinkButton;

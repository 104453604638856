export const blogSectionData = [
  {
    type: "text",
    name: "body",
    id: "body",
    label: "Body",
    placeholder: "We build ...",
  },
  {
    type: "textArea",
    name: "paragraph",
    id: "paragraph",
    label: "Paragraph",
    placeholder: "Type in your content here...",
  },
];

export const blogFieldArr = [
  {
    type: "text",
    name: "title",
    id: "title",
    label: "Title",
    placeholder: "Project Title 1...",
  },
  {
    type: "text",
    name: "url",
    id: "url",
    label: "Blog URL",
    placeholder: "",
  },
  {
    type: "text",
    name: "author",
    id: "author",
    label: "Author",
    placeholder: "",
  },
  {
    type: "text",
    name: "readTime",
    id: "readTime",
    label: "Author",
    placeholder: "",
  },
  {
    type: "text",
    name: "image",
    id: "image",
    label: "Blog Image",
    placeholder: "https://example.com/images/js-closures.jpg",
  },
];

export const blogTemplates = {
  title: "",
  paragraph: "",
  blogs: [
    {
      title: "",
      url: "",
      author: "",
      readTime: "",
      image: "",
    },
  ],
};

import React, { useEffect, useState } from "react";
import SearchBar from "../../components/dashboard/SearchBar";
import ProjectCard from "../../components/dashboard/ProjectCard";
import useRequest from "../../components/hook/use-request";
import { useSelector } from "react-redux";
import userSlice from "../../redux/slice/user-slice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  // const navigate = useNavigate();
  // const token = useSelector((state) => state.user.token);
  // const loading = useSelector((state) => state.user.loading);
  // const errorMessage = useSelector((state) => state.user.error);
  // const userData = {
  //   name: useSelector((state) => state.user.name),
  //   email: useSelector((state) => state.user.email),
  //   userId: useSelector((state) => state.user.userId),
  // };

  // const { makeRequest: getUserData, response } = useRequest(
  //   "/user",
  //   "GET",
  //   userSlice
  // );

  // useEffect(() => {
  //   if (!token) {
  //     toast.error("Authentication failed...please log in");
  //     setTimeout(() => {
  //       navigate("/auth/login");
  //     }, 3000);
  //   }
  //   const [statusCode] = getUserData();
  //   if (errorMessage && statusCode !== 200) {
  //     toast.error(errorMessage.payload);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // if (loading)
  //   return (
  //     <div className="fixed inset-0 bg-white flex justify-end items-center">
  //       <span>Loading ...</span>
  //     </div>
  //   );

  return (
    <div className="w-full h-full flex flex-col gap-8">
      <div className="flex gap-5 flex-wrap md:justify-between w-full">
        {/* <DashButton
          content="Add New"
          icon={<Icons.Plus className="w-4 h-4 self-center" />}
          className=""
        /> */}
        <SearchBar className="self-start" placeholder={"Search by keyword"} />
      </div>

      <main className="flex flex-col gap-2">
        <h1 className="text-base md:text-xl font-medium">Projects</h1>
        <section className="flex items-center flex-row flex-wrap gap-8 pt-3 pb-4 border-t border-slate-300">
          <ProjectCard
            title="Pinnacle"
            description="Padding website"
            to="/dashboard/projects/static"
          />
          <ProjectCard title="Ronaldo" description="An Officing website" />
        </section>
      </main>
    </div>
  );
}

export default Dashboard;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { blogSlice } from "../../../../redux/slice/blog-slice";
import { toast } from "react-toastify";
import DashLoader from "../../../../components/global/dash-loader";
import PropTypes from "prop-types";
import { replaceObjectKeys } from "../../../../utils/utilsFunction";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import ViewsCard from "../../../../components/dashboard/viewsCard";

function BlogSection({ blogData }) {
  console.log(blogData, "fffffffff");

  const filteredData = {
    body: blogData?.body,
    paragraph: blogData?.paragraph,
    blogs: blogData?.blogs,
  };

  const newKeys = ["Body", "Paragraph", "Blogs"];

  const newProjectData = replaceObjectKeys(filteredData, newKeys);

  console.log(newProjectData, "kkkkkkkkk");

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Projects Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
        {Object.entries(newProjectData).map(([dataKey, dataValues]) => {
          if (dataKey === "Blogs") {
            return (
              <div key={dataKey} className="flex flex-col gap-3 col-span-3">
                {/* Blogs Header */}
                <SectionHeading content={dataKey} className="self-center" />
                {/* Each Blog */}
                {dataValues.map((blog, blogIdx) => {
                  return (
                    <div
                      key={blogIdx}
                      className="flex flex-col gap-5 border-b border-gray-500 pb-5 font-semibold col-span-3 w-full"
                    >
                      {/* Blog subheading */}
                      <SectionHeading
                        content={`Blog ${blogIdx + 1}`}
                        className="!self-start text-[15px]"
                      />
                      <div className="w-full flex flex-col md:flex-row justify-between gap-3">
                        <ViewsCard content={blog?.title} />
                        <ViewsCard content={blog?.url} />
                        <ViewsCard content={blog?.author} />
                        <ViewsCard content={blog?.readTime} />
                        <ViewsCard
                          content={
                            <img src={blog?.image} alt={`Blog ${blogIdx}`} />
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );

            // Body and Paragraph
          } else {
            return (
              <div key={dataKey} className="flex flex-col gap-3 font-semibold">
                <h3>{dataKey}</h3>
                <ViewsCard content={dataValues} />
              </div>
            );
          }
        })}
      </div>
    </Section>
  );
}

BlogSection.propTypes = {
  blogData: PropTypes.object.isRequired,
};

function BlogV() {
  const loading = useSelector((state) => state.blogPage.loading);
  const error = useSelector((state) => state.blogPage.error);
  const blogData = useSelector((state) => state.blogPage.data);

  const { makeRequest: getBlogData } = useRequest(
    "/blog",
    "GET",
    {},
    blogSlice
  );

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getBlogData();
      if (response?.ok && statusCode === 200) {
        toast.success("Successfully fetched");
        return;
      }
      if (!response?.ok && statusCode === 400) {
        toast.error(`Unsuccessful: Bad request`);
        return;
      }
      if (!response?.ok && statusCode === 401) {
        toast.error(`Unauthorized...`);
        return;
      }
      if (!response?.ok && statusCode === 500) {
        toast.error(`Internal Server Error...Please try again`);
        return;
      }
      if (error && statusCode === 0 && blogData === null) {
        toast.error(error);
        return;
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !blogData) return <DashLoader message="Loading..." />;
  return (
    <div className="flex flex-col gap-8 pt-6 pb-10">
      <h1 className="text-xl self-center md:text-xl font-semibold">
        Blog Page
      </h1>
      {blogData ? (
        <div className="flex flex-col gap-8">
          <BlogSection blogData={blogData} />
        </div>
      ) : (
        <span>Fetching Data ...</span>
      )}
    </div>
  );
}

export default BlogV;

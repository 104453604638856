import React from "react";
import PropTypes from "prop-types";

function Section({ children = <div>Input your children</div>, className }) {
  return (
    <section
      className={`px-4 py-4 border border-gray-200 bg-slate-100 rounded-md ${className}`}
    >
      {children}
    </section>
  );
}

Section.prototype = {
  children: PropTypes.elementType,
  className: PropTypes.string,
};

export default Section;

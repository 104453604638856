import React from "react";
import PropTypes from "prop-types";

function SectionHeading({ content = "Add Heading", className }) {
  return (
    <h1
      className={`text-lg text-green-700 px-3 py-1 border border-slate-300 bg-slate-200 rounded-md font-medium self-center ${className}`}
    >
      {content}
    </h1>
  );
}

SectionHeading.prototype = {
  content: PropTypes.string,
  className: PropTypes.string,
};

export default SectionHeading;

import { toast } from "react-toastify";

export const handleResponse = (
  response,
  statusCode,
  error,
  data,
  message = null
) => {
  if (response?.ok && (statusCode === 200 || statusCode === 201)) {
    toast.success(message);
    return "successful";
  }
  if (!response?.ok && statusCode === 400) {
    toast.error(`Unsuccessful: Bad request`);
    return "bad request";
  }
  if (!response?.ok && statusCode === 401) {
    toast.error(`Unauthorized...`);
    return "unauthorized";
  }
  if (!response?.ok && statusCode === 500) {
    toast.error(`Internal Server Error...Please try again`);
    return "server error";
  }
  if (error && statusCode === 0 && data === null) {
    toast.error(error);
    return;
  }
};

import React from "react";
import LinkButton from "../components/global/link-button";

function LandingPage() {
  return (
    <div  className="flex justify-center items-center h-screen overflow-hidden bg-black/80 px-2 md:px-8">
      <section className="shadow-lg px-8 py-8 flex flex-col gap-5 items-center w-full md:max-w-[50vw] lg:w-[30vw] lg:max-w-[50vw] border border-slate-200 bg-white rounded-md">
        <img src="/images/padding_logo.jpg" alt="Padding Logo" className="w-10 h-10" />
        <h1 className="font-bold text-base lg:text-xl">Welcome to Padding CMS</h1>
        <div className="flex gap-3 flex-wrap justify-center items-center">
            <LinkButton content="Login" route="/auth/login" />
            <LinkButton content="Register" route="/auth/register" />
        </div>
      </section>
    </div>
  );
}

export default LandingPage;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import AuthButton from "../../components/global/auth-button";
import { Link, useNavigate } from "react-router-dom";
import EyeToggle from "../../components/global/eye-toggle";
import { registerFormFields } from "../../utils/registerFormFields";
import { Icons } from "../../components/global/icon";
import useRequest from "../../components/hook/use-request";
import { toast } from "react-toastify";
import userSlice from "../../redux/slice/user-slice";
import { useSelector } from "react-redux";


function Register() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cPasswordVisible, setCPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const { register, handleSubmit, formState, watch } = useForm();
  const { errors } = formState;
  const password = watch("password");

  const errorMessage = useSelector((state) => state.user.error)
  

  const { loading, makeRequest } = useRequest(
    "/users/register",
    "POST",
    userSlice
  );

  const registerUser = async (formData) => {
    const admin = {
      name: formData.fullName,
      email: formData.email,
      password: formData.password,
    };
    const [response, statusCode] = await makeRequest(admin);
    

    if (statusCode === 201 && response) {
      // On successful registration, show success toast and navigate
      toast.success("Registration successful! Redirecting to login...");
      setTimeout(() =>{
        navigate("/auth/login");
      },3000)    
    }
    if(errorMessage){
      toast.error(errorMessage.payload)
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleCPasswordVisibility = () => {
    setCPasswordVisible(!cPasswordVisible);
  };

  return (
    <div className="flex overflow-hidden h-screen">
      {/* Image section */}
      <div className="hidden md:block">
        <img
          src="/images/cms_bg.jpg"
          alt="Background"
          className="lg:w-[50vw] object-cover md:h-screen"
        />
      </div>

      <main className="flex justify-center items-center px-10 lg:px-0 w-full lg:w-[50vw] h-full overflow-auto pt-20">
        <div className="flex flex-col gap-7 w-full lg:w-[25rem] max-w-[20rem] lg:max-w-[25rem]">
          {/* Header */}
          <img
            src="/images/padding_logo.jpg"
            alt="Padding Logo"
            className="w-10 h-10 self-center"
          />
          <div className="flex flex-col gap-1 items-center">
            <h1 className="font-semibold text-xl lg:text-2xl">
              Welcome to Padding CMS
            </h1>
            <p className="flex gap-1 text-xs">
              <span>Already have an account?</span>
              <Link to="/auth/login" className="underline font-medium">
                Login
              </Link>
            </p>
          </div>

          {/* Form section */}
          <form
            onSubmit={handleSubmit(registerUser)}
            className="flex flex-col gap-7"
          >
            <div className="w-full flex flex-col gap-4">
              {/* Full Name field */}
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="fullName" className="text-sm font-medium">
                  Full Name
                </label>
                <input
                  type="text"
                  {...register("fullName", {
                    required: {
                      value: true,
                      message: `full name is required`,
                    },
                  })}
                  placeholder="John Doe"
                  id="fullName"
                  className="border border-slate-400 px-4 py-2 focus:outline-none focus:border-green-300 rounded-md"
                />
                {errors.fullName && (
                  <p className="text-red-700 text-xs">
                    {errors.fullName?.message}
                  </p>
                )}
              </div>

              {/* Email */}
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="email" className="text-sm font-medium">
                  Email
                </label>
                <input
                  type="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: `email is required`,
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email format",
                    },
                  })}
                  placeholder="johndoe@gmail.com"
                  id="email"
                  className="border border-slate-400 px-4 py-2 focus:outline-none focus:border-green-300 rounded-md"
                />
                {errors.email && (
                  <p className="text-red-700 text-xs">
                    {errors.email?.message}
                  </p>
                )}
              </div>

              {/* Password and confirm password fields */}
              {registerFormFields.map((field, fieldIndex) => {
                const isPasswordVisible =
                  field.name === "password"
                    ? passwordVisible
                    : cPasswordVisible;

                const toggleVisibility =
                  field.name === "password"
                    ? togglePasswordVisibility
                    : toggleCPasswordVisibility;

                return (
                  <div key={fieldIndex} className="flex flex-col gap-2 w-full">
                    <label htmlFor={field.id} className="text-sm font-medium">
                      {field.label}
                    </label>
                    {/* Input */}
                    <div className="relative">
                      <input
                        type={`${isPasswordVisible ? "text" : field.type}`}
                        {...register(field.name, {
                          required: {
                            value: true,
                            message: `${field.placeholder} is required`,
                          },
                          pattern:
                            field.name === "password"
                              ? {
                                  value:
                                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                                  message:
                                    "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character",
                                }
                              : undefined,
                          validate:
                            field.name === "confirm_password"
                              ? (value) =>
                                  value === password || "Passwords do not match"
                              : undefined,
                        })}
                        placeholder={field.placeholder}
                        id={field.id}
                        className="border border-slate-400 pl-4 pr-12 py-2 focus:outline-none w-full focus:border-green-300 rounded-md"
                      />

                      {/* Eye toggle */}
                      {!isPasswordVisible && (
                        <EyeToggle
                          content={
                            <Icons.Eye
                              strokeWidth={1.4}
                              className="text-slate-500"
                            />
                          }
                          className="absolute top-2 right-3"
                          onClick={toggleVisibility}
                        />
                      )}
                      {isPasswordVisible && (
                        <EyeToggle
                          content={
                            <Icons.EyeOff
                              strokeWidth={1.3}
                              className="text-slate-500"
                            />
                          }
                          className="absolute top-2 right-3"
                          onClick={toggleVisibility}
                        />
                      )}
                    </div>
                    {errors[field.name] && (
                      <p className="text-red-700 text-xs">
                        {errors[field.name]?.message}
                      </p>
                    )}
                  </div>
                );
              })}

              {/* Remember me */}
              <div className="flex text-xs justify-between flex-wrap">
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    {...register("remember_password", {
                      required: {
                        value: true,
                        message: "Please accept the terms and privacy policy",
                      },
                    })}
                    id="remember_password"
                    className="self-center accent-green-400"
                  />
                  <label htmlFor="terms" className="flex gap-1 flex-wrap">
                    <span>I agree to the</span>
                    <Link href="." className="underline">
                      Terms of service
                    </Link>
                    <span>and</span>
                    <Link href="." className="underline">
                      Privacy of Policy
                    </Link>
                  </label>
                </div>
                {errors["remember_password"] && (
                  <p className="text-red-700 text-xs">
                    {errors["remember_password"]?.message}
                  </p>
                )}
              </div>
            </div>

            <AuthButton
              content="Register"
              className="w-full"
              isLoading={loading}
            />
          </form>
          <Link className="flex gap-2 self-center text-sm" to={"/"}>
            <Icons.ArrowLeft width={18} height={18} className="self-center" />
            <span>Back home</span>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Register;

import { ArrowLeft, Bell, ChevronDown, ChevronUp, Edit, Eye, EyeOff, LayoutDashboard, LogOut, Menu, Moon, Plus, Search, Settings, Trash2, UserPlus, X } from "lucide-react";

export const Icons = {
  Moon: Moon,
  Bell: Bell,
  Menu: Menu,
  Eye: Eye,
  EyeOff: EyeOff,
  ArrowLeft: ArrowLeft,
  DashoardIcon: LayoutDashboard,
  Collapse: ChevronDown,
  Enlarge: ChevronUp,
  Plus: Plus,
  Settings: Settings,
  UserPlus: UserPlus,
  LogOut: LogOut,
  Search: Search,
  Edit: Edit,
  TrashTwo: Trash2,
  Cancel: X,
};